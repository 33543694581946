var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useWizard } from '@shared/ui/misc/Wizard';
import { ImageEditStatus } from '@src/entities/profile';
import { useAppSelector } from '@app/store/Hooks';
import { getAvatarEncodedImages } from './utils';
var EditAvatarSteps;
(function (EditAvatarSteps) {
    EditAvatarSteps[EditAvatarSteps["Initial"] = 0] = "Initial";
    EditAvatarSteps[EditAvatarSteps["Edit"] = 1] = "Edit";
    EditAvatarSteps[EditAvatarSteps["Save"] = 2] = "Save";
    EditAvatarSteps[EditAvatarSteps["Warning"] = 3] = "Warning";
    EditAvatarSteps[EditAvatarSteps["ConfirmDelete"] = 4] = "ConfirmDelete";
})(EditAvatarSteps || (EditAvatarSteps = {}));
const useAvatarEdit = ({ setValue, getValues, watch }) => {
    const { current, goTo } = useWizard();
    const { currentUserProfile, } = useAppSelector((state) => state.profile);
    const [isConverting, setIsConverting] = useState(false);
    const tempImage = watch('imageEditorState.tempImage');
    const imageToDisplay = watch('imageEditorState.initialImage');
    const publicName = watch('displayName');
    const confirmClose = () => {
        setValue('imageEditorState.isOpen', false);
        setValue('imageEditorState.prevStep', EditAvatarSteps.Initial);
    };
    const handleSave = () => {
        setValue('image', {
            avatar: getValues('imageEditorState.avatar'),
            original: getValues('imageEditorState.original'),
            avatarImg: getValues('imageEditorState.initialImage'),
            thumbnail: getValues('imageEditorState.thumbnail'),
        });
        setValue('imageEditorState.imageEditStatus', ImageEditStatus.Updated);
        confirmClose();
    };
    const getImageFromCanvas = (canvasElem) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        setIsConverting(true);
        const encodedImages = yield getAvatarEncodedImages({
            avatar: canvasElem,
            imageFileType: (_a = getValues('imageEditorState.tempImage')) === null || _a === void 0 ? void 0 : _a.type,
            imageFileName: ((_b = getValues('imageEditorState.tempImage')) === null || _b === void 0 ? void 0 : _b.name) || '',
        });
        if (encodedImages.status === 'success' && setValue) {
            const [original, avatar, thumbnail, avatarImg] = encodedImages.value;
            setValue('imageEditorState', {
                avatar: avatar,
                original: original,
                thumbnail: thumbnail,
                initialImage: avatarImg,
                isOpen: true,
            });
            setIsConverting(false);
            return true;
        }
        setIsConverting(false);
        return false;
    });
    const goToNextStep = (element) => __awaiter(void 0, void 0, void 0, function* () {
        switch (current) {
            case EditAvatarSteps.Initial:
                if (element && !(element instanceof Node)) {
                    setValue('imageEditorState.tempImage', element);
                    setValue('imageEditorState.prevStep', EditAvatarSteps.Edit);
                    goTo(EditAvatarSteps.Edit);
                }
                break;
            case EditAvatarSteps.Edit:
                if (element && element instanceof Node) {
                    const res = yield getImageFromCanvas(element);
                    if (res) {
                        setValue('imageEditorState.prevStep', EditAvatarSteps.Save);
                        goTo(EditAvatarSteps.Save);
                    }
                }
                break;
            case EditAvatarSteps.Save:
                handleSave();
                break;
            default:
                break;
        }
    });
    const handleClose = () => {
        goTo(EditAvatarSteps.Warning);
    };
    const handleDelete = () => {
        if ((currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) === 'CLIENT') {
            goTo(EditAvatarSteps.ConfirmDelete);
        }
    };
    const confirmDelete = () => {
        if ((currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) === 'CLIENT' && setValue) {
            setValue('image', null);
            setValue('imageEditorState.initialImage', undefined);
            setValue('initialImage', undefined);
            setValue('imageEditorState.isOpen', false);
            setValue('imageEditorState.imageEditStatus', ImageEditStatus.Deleted);
        }
    };
    const declineDelete = () => {
        if ((currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) === 'CLIENT') {
            goTo(EditAvatarSteps.Save);
        }
    };
    const returnToEdit = () => {
        if (getValues && typeof getValues('imageEditorState.prevStep') === 'number') {
            goTo(getValues('imageEditorState.prevStep'));
        }
    };
    const reuploadImage = (file) => {
        setValue('imageEditorState.tempImage', file);
        setValue('imageEditorState.prevStep', EditAvatarSteps.Edit);
        goTo(EditAvatarSteps.Edit);
    };
    return {
        goToNextStep,
        publicName,
        tempImage,
        imageToDisplay,
        handleClose,
        handleDelete,
        handleSave,
        confirmDelete,
        declineDelete,
        confirmClose,
        returnToEdit,
        reuploadImage,
        isConverting,
        isTraderProfile: (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) === 'TRADER',
    };
};
export default useAvatarEdit;
